import { consumeService } from '@/api';
import { DataDictionaryService } from '@/api/data-dictionary';
import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum } from '@/resource/enum';
import { ConsumeDetails, ProductConsumedResource } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { translation, messageError, decimalToNumber6 } from '@/utils';
import Decimal from 'decimal.js';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class EditConsume extends mixins(DialogMixin) {
  @Prop({ required: true, type: Number })
  public consumeId!: number;

  public consumeDetails: ConsumeDetails | null = null;

  public formRules = {
    attritionCount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value <= 0) {
            callback(new Error(translation('consumed.inputConsumedCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    // dutyUser: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: number, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('consumed.inputDutyUser')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'blur'
    //   }
    // ],
    calcUnit: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('consumed.selectConsumedMeasureType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    dutyReason: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('consumed.inputReason')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    attritionArea: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value < 0) {
            callback(new Error(translation('consumed.inputBrokenArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public consumeForm: Partial<ProductConsumedResource> = {
    dutyReason: '',
    attritionCount: 1,
    attritionType: '',
    attritionLink: '',
    calcUnit: ChargeUnitEnum.squareMeter,
    attritionArea: 0,
    remark: '',
    findUser: '',
    findOrganize: '',
    dutyUser: '',
    dutyOrganize: '',
    dutyOrganizeType: undefined
  };

  /**
   * 耗损环节
   */
  public consumeStages: NormalSelectOptions = [];

  /**
   * 耗损类型
   * 每个耗损环节对应不同的耗损类型
   */
  public consumeTypes: NormalSelectOptions = [];

  /**
   * 责任机构类型
   */
  public dutyAgencyTypes: NormalSelectOptions = consumeService.getDutyAgencyTypes();

  /**
   * 计价单位
   */
  public priceUnits: NormalSelectOptions = consumeService.getPriceUnits();

  public get isShowCountInput(): boolean {
    return this.consumeForm.calcUnit !== ChargeUnitEnum.squareMeter;
  }

  public get brokenArea(): string {
    return `${translation('consumed.brokenArea')}：${(this.consumeForm.attritionArea ?? 0).toFixed(6)}㎡`;
  }

  public created(): void {
    this.getConsumeStages();
    this.getConsumeDetails(this.consumeId).then(() => {
      if (this.consumeDetails!.attritionLink) {
        this.getConsumeTypeByStage(this.consumeDetails!.attritionLink);
      }
    });
  }

  public async onSubmit(): Promise<void> {
    try {
      this.setLoading(true);
      await (this.$refs.consumeForm as ElForm).validate();
      await consumeService.put(this.consumeForm as ProductConsumedResource);
      Message.success(translation('operationRes.operationSuccess'));
      this.closeDialog();
      this.$emit('consumed-success');
    } catch (error) {
      if (error) messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public async handleConsumeStageChanged(stageCode: string): Promise<void> {
    this.getConsumeTypeByStage(stageCode);
  }

  private calBrokenArea(): void {
    this.consumeForm.attritionArea = decimalToNumber6(
      new Decimal(
        this.consumeForm.attritionCount! *
          (this.consumeDetails!.finishWidth / 1000) *
          (this.consumeDetails!.finishHeight / 1000)
      )
    );
  }

  /**
   * 默认的数量下（1）的耗损面积
   * @returns
   */
  private defaultAreaForNotSquareMeter(): number {
    return decimalToNumber6(
      new Decimal((this.consumeDetails!.finishWidth / 1000) * (this.consumeDetails!.finishHeight / 1000) * 1)
    );
  }

  private async getConsumeStages(): Promise<void> {
    try {
      this.consumeStages = (await DataDictionaryService.getConsumeStage()).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  private async getConsumeDetails(consumeId: number): Promise<void> {
    try {
      this.consumeDetails = await consumeService.getById(consumeId);
      Object.assign(this.consumeForm, this.consumeDetails);
    } catch (error) {
      messageError(error);
    }
  }

  private async getConsumeTypeByStage(stageCode: string): Promise<void> {
    try {
      this.consumeTypes = (await DataDictionaryService.getConsumeType(stageCode)).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
}
